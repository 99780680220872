import { useState, useEffect } from 'react';

interface CurrencyResponse {
  success: boolean;
  rates: Record<string, number>;
}

export const useNewCurrencies = () => {
  const [currencies, setCurrencies] = useState<Array<{ label: string; value: string }>>([]);

  useEffect(() => {
    const fetchCurrencies = async () => {
      console.log('Fetching currencies...'); 
      try {
        const response = await fetch('https://api.exchangeratesapi.io/v1/latest?access_key=895980f98826238f1edc3e5f839ce3e3');
        console.log('Response:', response);
        const data: CurrencyResponse = await response.json();
        
        if (data.success) {
          const currencyOptions = Object.keys(data.rates).map(currency => ({
            label: currency,
            value: currency
          }));
          setCurrencies(currencyOptions);
        }
      } catch (error) {
        console.error('Error fetching currencies:', error);
      }
    };

    fetchCurrencies();
  }, []);

  return currencies;
};