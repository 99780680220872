// components/layout/SiteFooter.tsx
import { Button } from "@/components/ui/button";
import { useLoadReceipts } from "@/hooks/useLoadReceipts";
import { To, useLocation, useNavigate } from "react-router-dom";
import { triggerHapticFeedback } from "@/utils/hapticFeedback";
// import DrawerComponent from "../AddFooterButton";

import {
  CheckFat,
  // Calculator,
  // ClockCounterClockwise,
  // ListChecks,
  // Money,
  MoneyWavy,
  Plus,
  // Receipt,
  Wallet,
  Trophy,
  Graph,
} from "@phosphor-icons/react";
import { useProfileQuery } from "@/queries/profiles";

export function SiteFooter() {
  const { data: profile } = useProfileQuery();
  const location = useLocation();
  const navigate = useNavigate();

  useLoadReceipts();

  if (!profile) {
    return null;
  }

  const handleNavigation = async (path: To) => {
    triggerHapticFeedback(); // trigger haptic feedback
    navigate(path);
  };

  return (
    <footer className="fixed bottom-0 z-50 w-full border-t bg-background">
      <div className="container px-6 lg:px-8 mx-auto flex h-16 max-w-screen-sm items-center justify-between">
        <div
          onClick={() => handleNavigation("/")}
          className="flex flex-col items-center mr-2 cursor-pointer"
        >
          <CheckFat
            size={30}
            className={`${
              location.pathname === "/" 
                ? "text-primary" 
                : "text-muted-foreground hover:text-primary transition-colors"
            }`}
          />
        </div>
        <div
          onClick={() => handleNavigation("/leaderboard")}
          className="flex flex-col items-center cursor-pointer"
        >
          <Trophy
            size={30}
            className={`${
              location.pathname?.startsWith("/leaderboard") 
                ? "text-primary" 
                : "text-muted-foreground hover:text-primary transition-colors"
            }`}
          />
        </div>
        <div
          onClick={() => handleNavigation("/agents")}
          className="flex flex-col items-center cursor-pointer"
        >
          <Graph
            size={30}
            className={`${
              location.pathname.startsWith("/agents") 
                ? "text-primary" 
                : "text-muted-foreground hover:text-primary transition-colors"
            }`}
          />
        </div>
        <div
          onClick={() => handleNavigation("/wallet")}
          className="flex flex-col items-center ml-2 cursor-pointer"
        >
          <Wallet
            size={30}
            className={`${
              location.pathname === "/dashboard" 
                ? "text-primary" 
                : "text-muted-foreground hover:text-primary transition-colors"
            }`}
          />
        </div>
      </div>
    </footer>
  );
}

export default SiteFooter;
