// components/forms/ProfileFormBase.tsx
"use client";

import { createAvatar } from "@dicebear/core";
import { openPeeps } from "@dicebear/collection";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useToast } from "../ui/use-toast";
import { Profile } from "@/types/entities";
import { useLocalTimezone, timezones } from "../data/timezones";
import { genderOptions } from "../data/gender";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "../ui/form";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { ArrowClockwise } from "@phosphor-icons/react";
import { Textarea } from "../ui/textarea";
import SelectablePopover from "../ui/SelectablePopover";
import { getRandomColor } from "@/hooks/useBackground";
import { isTMA, retrieveLaunchParams } from "@telegram-apps/sdk";
import CopyToClipboard from "../CopyToClipboard";
import { deleteProfile } from "@/atoms/profile";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import DeleteDialog from "@/components/ui/DeleteDialog";
import { useNewCurrencies } from "@/hooks/useNewCurrencies";
import { useProfileMutation } from "@/queries/profiles";
import { useQueryClient } from "@tanstack/react-query";

export type ProfileFormBaseProps = {
  profile: Profile | null;
  onSubmit: (data: FormSchema) => void;
  title: string;
  buttonText?: string;
  isEditMode: boolean;
};

const formSchema = z.object({
  avatar_svg: z.string().optional(),
  color: z.string().optional(),
  username: z
    .string()
    .min(3, { message: "Username should be at least 3 characters long" })
    .max(100, { message: "Username should be <= 100 characters long" }),
  bio: z.string().max(160, { message: "Bio should be <= 160 characters long" }),
  currency: z.string({
    required_error: "Currency is required",
  }),
  timezone: z.string({
    required_error: "Timezone is required",
  }),
  gender: z.string({
    required_error: "Gender is required",
  }),
  privacy_policy: z
    .boolean()
    .refine((val) => val === true, {
      message: "You must agree to the privacy policy",
    })
    .nullable(),
  evm_address: z.string().optional(),
  ton_address: z.string().optional(),
});

export type FormSchema = z.infer<typeof formSchema>;

export const ProfileFormBase: React.FC<ProfileFormBaseProps> = ({
  profile,
  onSubmit,
  title,
  buttonText = "Proceed",
  isEditMode = false,
}) => {
  const { toast } = useToast();
  const localTimezone = useLocalTimezone();
  const profileMutation = useProfileMutation();
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const newCurrencies = useNewCurrencies();
  // console.log("Currencies are: ", newCurrencies);

  const [backgroundColor, setBackgroundColor] = useState<string>(
    profile?.color || getRandomColor()
  );
  const [seed, setSeed] = useState<string>(new Date().toJSON());
  const [avatar_svg, setavatar_svg] = useState<string>(
    profile?.avatar_svg || genAvatar(seed)
  );
  const [saving, setSaving] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false); // State for delete dialog

  useEffect(() => {
    async function init() {
      if (await isTMA()) {
        const launchParams = await retrieveLaunchParams();
        const username = launchParams?.initData?.user?.username || "";
        form.setValue("username", username);
      }
    }
    init();
  }, []);

  function genAvatar(seed: string): string {
    return createAvatar(openPeeps, {
      seed: seed,
      backgroundColor: [backgroundColor],
    }).toString();
  }

  function handleRegenerate(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.preventDefault();
    const _color = getRandomColor();
    setBackgroundColor(_color);
    const _newSeed = new Date().toJSON();
    setSeed(_newSeed);
    const _newavatar_svg = genAvatar(_newSeed); // Fix seed issue here
    setavatar_svg(_newavatar_svg);
    form.setValue("avatar_svg", _newavatar_svg);
    form.setValue("color", _color);
  }

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: profile?.username ?? "",
      bio: profile?.bio ?? "",
      gender: profile?.gender ?? "",
      currency: profile?.currency ?? "",
      timezone: localTimezone ?? "",
      privacy_policy: isEditMode ? null : false, // Conditional default value based on mode
      evm_address: profile?.evm_address ?? "",
      ton_address: profile?.ton_address ?? "",
    },
  });

  useEffect(() => {
    form.setValue("timezone", localTimezone);
  }, [localTimezone, form]);

  const handleSubmit = async (data: FormSchema) => {
    setSaving(true);

    try {
      await onSubmit({ ...data, avatar_svg });
      toast({ title: "Profile updated successfully" });
    } catch (error) {
      if (error instanceof z.ZodError) {
        error.errors.forEach((err) => {
          form.setError(err.path[0] as keyof FormSchema, {
            type: "manual",
            message: err.message,
          });
        });
      } else {
        toast({ title: "Failed to update profile" });
      }
    } finally {
      setSaving(false);
    }
  };

  const handleDelete = async () => {
    if (profile && profile.id) {
      setSaving(true);
      try {
        const response = await deleteProfile(profile.id);
        if (response.success) {
          queryClient.setQueryData(["profile"], null);
          // navigate("/onboarding");
          navigate("/intro");
          toast({
            title: "Profile and related transaction delete successfully",
          });
        } else {
          toast({ title: response.message });
        }
      } catch (error) {
        console.error("Error deleting profile:", error);
        toast({ title: "Failed to delete profile" });
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <Card className="w-full max-w-2xl mx-auto mb-10">
      <CardHeader>
        <CardTitle className="text-center">{title}</CardTitle>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className="space-y-8"
          >
            <div className="flex flex-col gap-4 items-center">
              <div
                className="w-48 h-48 rounded-lg mx-auto"
                style={{ backgroundColor }}
                dangerouslySetInnerHTML={{ __html: avatar_svg }}
              ></div>
              <Button
                variant="secondary"
                onClick={handleRegenerate}
                className="flex justify-center items-center w-48 mx-auto"
              >
                Regenerate
              </Button>
            </div>

            <div className="flex flex-col items-center">
              <CopyToClipboard />
            </div>

            <FormField
              control={form.control}
              name="username"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Username <span className="text-red-500">*</span>
                  </FormLabel>
                  <FormControl>
                    <Input
                      placeholder="jacksparrow"
                      {...field}
                      maxLength={100}
                      disabled={saving}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="bio"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Bio</FormLabel>
                  <FormControl>
                    <Textarea placeholder="" {...field} disabled={saving} />
                  </FormControl>
                  <FormDescription>A few words about yourself</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="gender"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Gender <span className="text-red-500">*</span>
                  </FormLabel>
                  <SelectablePopover
                    label=""
                    placeholder="Select gender"
                    options={genderOptions}
                    value={field.value}
                    onChange={(value) => form.setValue("gender", value)}
                    control={form.control}
                    name="gender"
                  />
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="timezone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    Timezone <span className="text-red-500">*</span>
                  </FormLabel>
                  <FormControl>
                    {localTimezone ? (
                      <Input value={localTimezone} disabled />
                    ) : (
                      <SelectablePopover
                        label="Timezone"
                        placeholder="Select timezone"
                        options={timezones}
                        value={field.value}
                        onChange={(value) => form.setValue("timezone", value)}
                        control={form.control}
                        name="timezone"
                      />
                    )}
                  </FormControl>
                  <FormMessage />
                  <FormDescription>
                    {localTimezone
                      ? "This timezone is automatically detected based on your current location."
                      : ""}
                  </FormDescription>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="currency"
              render={({ field, fieldState }) => (
                <FormItem>
                  <FormLabel>
                    Currency <span className="text-red-500">*</span>
                  </FormLabel>
                  <SelectablePopover
                    label=""
                    placeholder="Select currency"
                    options={newCurrencies}
                    value={field.value}
                    onChange={(value) => form.setValue("currency", value)}
                    control={form.control}
                    name="currency"
                    className={fieldState.invalid ? "border-red-500" : ""}
                  />
                  {fieldState.invalid && (
                    <span className="text-red-500 text-sm mt-1">
                      {fieldState.error?.message}
                    </span>
                  )}
                </FormItem>
              )}
            />

            {!isEditMode && ( // Only show Privacy Policy when not in edit mode
              <FormField
                control={form.control}
                name="privacy_policy"
                render={({ field }) => (
                  <FormItem className="flex items-center space-x-2">
                    <FormControl>
                      <Checkbox
                        id="privacy_policy"
                        checked={field.value || false}
                        onCheckedChange={(checked) => field.onChange(checked)}
                        onBlur={field.onBlur}
                        name={field.name}
                        ref={field.ref}
                        className="mt-2"
                      />
                    </FormControl>
                    <FormLabel htmlFor="privacy_policy" className="text-sm">
                      I agree to the Privacy Policy{" "}
                      <span className="text-red-500">*</span>
                    </FormLabel>
                  </FormItem>
                )}
              />
            )}

            <Button
              type="submit"
              className="w-full"
              disabled={
                saving || (!isEditMode && !form.watch("privacy_policy"))
              }
            >
              {saving ? (
                <>
                  <ArrowClockwise className="mr-2 h-4 w-4 animate-spin" />
                  Saving...
                </>
              ) : (
                <>{buttonText}</>
              )}
            </Button>

            {profile && (
              <>
                <div className="flex items-center my-4">
                  <div className="border-t border-gray-400 flex-grow"></div>
                  <span className="mx-4 text-gray-400">or</span>
                  <div className="border-t border-gray-400 flex-grow"></div>
                </div>
                <Button
                  type="button"
                  className="w-full mt-4 bg-red-500 text-white"
                  onClick={() => setDeleteDialogOpen(true)}
                >
                  Delete
                </Button>
              </>
            )}
          </form>
        </Form>
      </CardContent>
      {profile && (
        <DeleteDialog
          open={deleteDialogOpen}
          onOpenChange={setDeleteDialogOpen}
          onDelete={handleDelete}
          isDeleting={saving}
          text="profile"
        />
      )}
    </Card>
  );
};
